@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

body {
  color: black;
}

.filter--notFound {
  display: none;
}

/* Notifications Start */
@keyframes notification-countdown {
  from {
    width: 100%;
  }
  to {
    width: 0;
  }
}

/* Notifications End */